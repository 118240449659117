.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  min-height: 60rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-grid;
  min-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flow-root;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Smooth Transitions */
.page {
  animation-duration: 0.25s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

