.navbar {
    background-color: #333;
    height: 3rem;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    width:100%;
    
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 12rem;
}

.navbar-logo {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
}

img{
    width:5rem;
    height:5rem;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
}

.nav-item {
    height: 8rem;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-links:hover {
    background-color: rgb(192, 192, 192);
    border-radius: 0.5rem;
    color: black;
}

a{
    text-decoration: none;
}

#logo {
    display: flex;
    align-items: center;
    color: #fff
}

#imglogo {
    margin-right: 1rem; /* Adjust the spacing between the image and the label */
}

#lbllogo {
    font-size: 2rem; /* Adjust the font size as needed */
}

#lbllogo:hover {
    text-decoration: underline;
}