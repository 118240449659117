footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    margin-top: auto;
    position:fixed;
    bottom:0;
    width: 100%;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    align-items: center;
    margin: 0 auto;
}

.footer-logo {
    width: 3rem;
    height: 3rem;
    margin-right:1.5rem;
}

.footer-menu {
    display: flex;
    list-style: none;
    text-align: center;
    display: inline-block;
}

.footer-item {
    margin: 0 1rem;
}

.footer-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.footer-links:hover {
    background-color: rgb(192, 192, 192);
    border-radius: 1rem;
    color: black;
}

.footer-info {
    text-align: center;
    margin-top: 1rem;
    
    p{
        display: inline-block;
    }
    
}

.footer-social-link {
    color: #fff;
    text-decoration: none;
    margin: 0 1rem;
}

.footer-social-link:hover {
    color: rgb(192, 192, 192);
}