.material-symbols-outlined {
  vertical-align: middle;
  display: inline-block;
  transition: transform 0.8s;
}

/* Keyframes for the bounce animation */
@keyframes bounceRight {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }
}

/* Apply the animation on hover */
.bounce:hover {
  animation: bounceRight 0.5s ease-in-out;
}

.homemain {
  display: flex;
  justify-content: center;
  align-items: center;
  height:52rem;
  margin-top: 1rem;
  margin-left: 8rem;
}

.homemain-item {
  margin-left: 1rem;
  display:flex;
  justify-content: space-around;
  padding: 0.8rem;
  margin-top: 8rem;
}

.homemain-item ul {
  text-align: left;
}

h5,h3 {
  text-align: start;
}

#summary {
  width: 20rem;
  text-align: left;
  margin-right: 1rem;
}

#skills {
  width: 20rem;
  margin-right: 1rem;
  h4 {
    text-align: center;
  }

  ul {
    margin-right: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .skill-category {

    border-radius: 0.5rem;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
}

#experience {
  width: 26rem;

  h4 {
    text-align: left;
  }
}

.card {

  width: 110rem;
  height: 40rem;
  max-width: 21rem;
  min-width: 21rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  color: black;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);

  img {
    position: absolute;
    object-fit: scale-down;
    width: 21rem;
    height: 38rem;
    border-radius: 1rem;
    top: 0;
    left: 0;
    opacity: 0.9;
    transition: opacity .2s ease-out;
  }

  h2 {
    position: absolute;
    inset: auto auto 2.4rem 0.4rem;
    margin: 0;
    transition: inset .3s .3s ease-out;
    font-weight: normal;
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  p,
  a {
    position: absolute;
    opacity: 0;
    max-width: 90%;
    transition: opacity 0.1s ease-out;
  }

  p {
    inset: auto auto 12rem 8rem;
  }


  a {
    inset: auto auto 1rem 0rem;
    color: inherit;
    text-decoration: none;
  }

  &:hover h2 {
    inset: auto auto 32rem 0rem;
    transition: inset .3s ease-out;
  }

  &:hover p,
  &:hover a {
    opacity: 1;
    transition: opacity .5s .1s ease-in;
    border-radius: 0.5rem;
    padding: 0.5rem;

  }

  &:hover img {
    transition: opacity .3s ease-in;
    opacity: 1;
  }

}

.material-symbols-outlined {
  vertical-align: middle;
}

